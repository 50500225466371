import { storyblokEditable } from '@storyblok/react/rsc'
import { clsx } from 'clsx'
import { type FC } from 'react'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokHeadingType } from '../../model/storyblokTypes.generated'

const alignmentClassMap = {
  center: 'text-center',
  left: 'text-left',
  right: 'text-right',
}

//This has been done to be user-friendly since these are the standard t-shirt sizes
/* eslint-disable id-length */
const sizeClassMap = {
  l: 'text-l',
  m: 'text-m',
  s: 'text-s',
  xl: 'text-xl',
  xs: 'text-xs',
  xxl: 'text-2xl',
}

export const StoryblokHeading: FC<
  StoryblokComponentProps<StoryblokHeadingType>
> = ({ blok }) => {
  const { alignment = 'left', size, tag, text } = blok

  const Element = tag

  return (
    <Element
      {...storyblokEditable(blok)}
      className={clsx(
        'font-medium',
        size ? sizeClassMap[size] : '',
        alignmentClassMap[alignment],
      )}
    >
      {text}
    </Element>
  )
}
